import { Row, Col } from "antd";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
    FooterSection,
    Title,
    NavLink,
    Extra,
    LogoContainer,
    Para,
    Large,
    Chat,
    Empty,
    FooterContainer,
    Language,
    Label,
    LanguageSwitch,
    LanguageSwitchContainer,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };
    
    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t("Contact")}</Language>
                            <Para>
                                {t(`Do you have any question? Feel free to reach out.`)}
                            </Para>
                            <a href="mailto:carolina.figueroa@xdevgt.com">
                                <Chat>{t(`Let's Chat`)}</Chat>
                            </a>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("Policy")}</Title>
                            <Large to="/">{t("Application Security")}</Large>
                            <Large to="/">{t("Software Principles")}</Large>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <Language>{t("Business Hours")}</Language>
                            <Para>{t("Schedule")}</Para>
                            <Para>8:00 AM - 5:00 PM (CST)</Para>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Empty />
                            <Language>{t("Address")}</Language>
                            <Para>{t("CompanyName")}</Para>
                            <Para>{t("Street")}</Para>
                            <Para>{t("City")}</Para>
                            <Empty />
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("Company")}</Title>
                            <Large to="/">{t("About")}</Large>
                            <Large to="/">{t("Blog")}</Large>
                            <Large to="/">{t("Press")}</Large>
                            <Large to="/">{t("Careers & Culture")}</Large>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <Label htmlFor="select-lang">{t("Language")}</Label>
                            <LanguageSwitchContainer>
                                <LanguageSwitch onClick={() => handleChange("en")}>
                                    <SvgIcon
                                        src="united-states.svg"
                                        aria-label="homepage"
                                        width="30px"
                                        height="30px"
                                    />
                                </LanguageSwitch>
                                <LanguageSwitch onClick={() => handleChange("es")}>
                                    <SvgIcon
                                        src="guatemala.svg"
                                        aria-label="homepage"
                                        width="30px"
                                        height="30px"
                                    />
                                </LanguageSwitch>
                            </LanguageSwitchContainer>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{ paddingTop: "3rem" }}
                    >
                        <NavLink to="/">
                            <LogoContainer>
                                <SvgIcon
                                    src="XD-Logo.png"
                                    aria-label="homepage"
                                    width="150px"
                                    height="62px"
                                />
                            </LogoContainer>
                        </NavLink>
                        <FooterContainer>
                            <span style={{ width: "100%", fontSize: "16px" }} >
                                    {t("All Rights Reserved", { Year: new Date().getFullYear() })}   
                                
                            </span>
                        </FooterContainer>
                    </Row>
                </Container>
            </Extra>
        </>
    );
};

export default withTranslation()(Footer);
