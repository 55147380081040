import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";

import aboutEn from "./locales/en/AboutContent.json";
import aboutEs from "./locales/es/AboutContent.json";

import contactEn from "./locales/en/ContactContent.json";
import contactEs from "./locales/es/ContactContent.json";

import introEn from "./locales/en/IntroContent.json";
import introEs from "./locales/es/IntroContent.json";

import middleEn from "./locales/en/MiddleBlockContent.json";
import middleEs from "./locales/es/MiddleBlockContent.json";

import missionEn from "./locales/en/MissionContent.json";
import missionEs from "./locales/es/MissionContent.json";

import productEn from "./locales/en/ProductContent.json";
import productEs from "./locales/es/ProductContent.json";

import customerEn from "./locales/en/CustomerContent.json";
import customerEs from "./locales/es/CustomerContent.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: "en",
        fallbackLng: "en",
        keySeparator: false, // Disables key nesting by dots, adjust if needed
        interpolation: {
            escapeValue: false, // React already escapes by default
        },

        resources: {
            en: {
                translations: { ...translationEn },
                about: { ...aboutEn },
                contact: { ...contactEn },
                intro: { ...introEn },
                middle: { ...middleEn },
                mission: { ...missionEn },
                product: { ...productEn },
                customer: { ...customerEn }
            },
            es: {
                translations: { ...translationEs },
                about: { ...aboutEs },
                contact: { ...contactEs },
                intro: { ...introEs },
                middle: { ...middleEs },
                mission: { ...missionEs },
                product: { ...productEs },
                customer: { ...customerEs }
            },
        },
        ns: ["translations", "about", "contact", "intro", "middle", "mission", "product", "customer"],
        defaultNS: "translations",
    });

export default i18n;
